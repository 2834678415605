@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/font/Merriweather-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/font/Merriweather-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

html {
  * {
    font-family: 'Montserrat', sans-serif;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.opacity {
  opacity: 0.7;
}

ul.square {
  list-style-type: square;

  li::marker {
    color: gray;
  }
}

ul.circle {
  list-style: none;
  padding-left: 12px;

  li {
    display: flex;
    align-items: center;

    span {
      width: 1rem;
      height: 1rem;
      background: #565656;
      display: inline-block;
      margin-right: 0.5rem;
      border-radius: 50%;
    }
  }

  $base-opacity: 0.2;

  @for $i from 1 through 5 {
    li:nth-child(#{$i}) span {
      opacity: $base-opacity * $i;
    }
  }
}

.selected {
  width: fit-content;
  border: 2px solid;
  border-radius: 15px;
  padding: 2px 10px 2px 5px;
  margin-left: -7px;
}

.equal-color {
  color: #df9203;
}
